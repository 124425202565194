import Grid from "@material-ui/core/Grid";
import { Card, Box } from "@material-ui/core";
import FileUpload from "../Controls/InputFile/InputFile";
import "./etapes.css";
import { getIndex } from "../../../../utils/FunctionHelpers";
import React from "react";
import EditorCustom from "../../../../components/EditorCustorm/EditorCustom";


export const Etape3 = ({
  softskills,
  setSoftskills,
  commentaire,
  setCommentaire,
  pointsforts,
  setPointsforts,
  cv,
  setCv,
  cvFormate,
  setCvFormate,
  autresPieces,
  setAutresPieces,
  editMode,
  oldFiles,
  setOldFiles
}) => {
  const updateCvFiles = (files) => setCv(files);
  const updateCvFormateFiles = (files) => setCvFormate(files);
  const updateAutresFiles = (files) => setAutresPieces(files);
  const updateOldFiles = (id) => {
    const array = [...oldFiles];
    const index = getIndex(id, array, "id");
    if (index !== -1) {
      array.splice(index, 1);
      setOldFiles(array);
    }
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const editors = [
    {
      label_1: "POINTS FORTS",
      value: pointsforts,
      setValue: setPointsforts,
      enableOnBlur: false
    },
    {
      label_1: "SOFTS SKILLS",
      value: softskills,
      setValue: setSoftskills,
      enableOnBlur: false
    },
    {
      label_1: "COMMENTAIRES",
      value: commentaire,
      setValue: setCommentaire,
      enableOnBlur: false
    }

  ]

  return (
    <>
      <Grid item={true} xs={12} sm={12}>
        <Card className="cards">
          <h4>SYNTHÈSE</h4>
          {
            editors.map((editor, key) => (
              <Box key={key + editor.label_1} sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: "1em" }}>
                <label className="input-label">{editor.label_1}</label>
                <EditorCustom {...editor} />
              </Box>
            ))
          }
        </Card>
      </Grid>
      <Grid item={true} xs={12} sm={12}>
        <Card className="cards piece_jointe" >
          <h4>PIÈCES JOINTES</h4>
          <Grid container spacing={2} sx={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={4} md={4} xl={4}>
              <h3>CV</h3>
              <FileUpload
                multiple
                updateFilesCb={updateCvFiles}
                files={cv}
                editMode={editMode}
                oldFiles={oldFiles.filter((oldFile) => oldFile.categorie === "cv")}
                updateOldFilesCb={updateOldFiles}
                acceptOnlyPdf={true}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={4}>
              <h3 >CV FORMATÉ</h3>
              <FileUpload
                multiple
                updateFilesCb={updateCvFormateFiles}
                files={cvFormate}
                editMode={editMode}
                oldFiles={oldFiles.filter((oldFile) => oldFile.categorie === "cvFormate")}
                updateOldFilesCb={updateOldFiles}
                acceptOnlyPdf={true}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} xl={4}>
              <h3>AUTRES</h3>
              <FileUpload
                multiple
                updateFilesCb={updateAutresFiles}
                files={autresPieces}
                editMode={editMode}
                oldFiles={oldFiles.filter((oldFile) => oldFile.categorie === "autrePiece")}
                updateOldFilesCb={updateOldFiles}
              />
            </Grid>
          </Grid>

        </Card>
      </Grid>
    </ >
  );
};
