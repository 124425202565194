import { BarChart, axisClasses } from "@mui/x-charts";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { withSnackbar } from "../../../../utils/SnackbarHOC";

function MonthlyEvolutionChart({ data, index }) {
  const valueFormatter = (value) => `${value}`;

  const chartSetting = {
    yAxis: [
      {
        domain: [0, 500],
      },
    ],
    height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "rotate(-90deg) translate(0px, -20px)",
      },
    },
  };
  return (
      <BarChart
          dataset={data}
          xAxis={[{ scaleType: "band", dataKey: "month" }]}
          series={
            index === 0
                ? [
                  { dataKey: "Notes", label: "Notes", valueFormatter },
                  { dataKey: "Nouvelles Missions", label: "Nouvelles Missions", valueFormatter },
                  { dataKey: "Envois", label: "Envois", valueFormatter },
                  { dataKey: "Signatures", label: "Signatures", valueFormatter },
                ]
                : [
                  { dataKey: "Prequalif", label: "Prequalif", valueFormatter },
                  { dataKey: "Envois client", label: "Envois client", valueFormatter },
                  { dataKey: "Propales", label: "Propales", valueFormatter },
                  { dataKey: "Embauche/Integration", label: "Signatures", valueFormatter },
                ]
          }
          {...chartSetting}
      />

  );
}

export default withRouter(withSnackbar(MonthlyEvolutionChart));
