import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  addCandidatSelection,
  editCandidatSelection,
} from "../../Services/SelectionCandidatService";
import {
  getUserData,
  updateObjectStateValue,
} from "../../../utils/FunctionHelpers";
import CustomAutoComplete from "../../../common/CustomAutoComplete";
import { getUsersByPairs } from "../../Services/UserService";
import { getMissionsActive } from "../../Services/MissionService";
import { useEffect } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  plus: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginRight: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const SelectionModal = ({
  selectedCandidats, history,
  snackbarShowMessage,
  children,
  ...otherProps
}) => {
  const loggedUser = {
    id: getUserData().uuid,
    value: getUserData().lastname + " " + getUserData().firstname,
  };
  const {
    isModify,
    idSelection,
    oldSelectionName,
    oldCreator,
    oldMission,
    setReloadSelections,
    reloadSelections,
    isImport,
    setImportData,
  } = otherProps;
  const classes = useStyles();
  const [modalState, setModalState] = useState({ open: false });
  const [modalStyle] = useState(getModalStyle);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectionData, setSelectionData] = useState({});
  const [creatorData, setCreatorData] = useState({
    isLoading: false,
    data: [],
    label: "TALENT MANAGER",
    required: true,
  });
  const [missionData, setMissionData] = useState({
    isLoading: false,
    data: [],
    label: "MISSION",
  });

  const handleChange = (event) => {
    setSelectionData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleChangeUser = (user) => {
    handleChange({
      target: {
        name: "creator",
        value: user,
      },
    });
  };

  const handleChangeMission = (mission) => {
    handleChange({
      target: {
        name: "mission",
        value: mission,
      },
    });
  };

  const validate = () => {
    let temp = {};
    temp.nomSelection = selectionData.selectionName
      ? ""
      : "Le champ 'NOM DE LA SÉLECTION' est obligatoire.";
    temp.sourceur = selectionData.creator
      ? ""
      : "Le champ 'CONSULTANT' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleOpen = () => {
    setModalState({ open: true });
  };

  const handleClose = () => {
    setModalState({ open: false });
  };

  const handleUpdate = () => {
    if (validate()) {
      snackbarShowMessage("Modification de la sélection...", "warning");
      setLoading(true);
      const payload = {
        ...selectionData,
        selectedCandidats,
        creator: selectionData.creator.id,
        mission: selectionData.mission?.id,
      };
      editCandidatSelection(payload, idSelection)
        .then(() => {
          snackbarShowMessage("La sélection a été modifiée avec succès.");
          setReloadSelections(!reloadSelections);
        })
        .catch(({ response }) => {
          snackbarShowMessage(response.data.error.detail, "error", 4000);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      snackbarShowMessage("Création de la sélection...", "warning", null);
      setLoading(true);
      const payload = {
        ...selectionData,
        selectedCandidats,
        creator: selectionData.creator.id,
        mission: selectionData.mission?.id,
      };
      addCandidatSelection(payload)
        .then(({ data }) => {
          if (isImport) {
            setReloadSelections(!reloadSelections);
            setImportData((prevState) => ({
              ...prevState,
              selection: data,
            }));
          }
          snackbarShowMessage("La sélection a été crée avec succès.", "success", 4000);
          history.push({
            pathname: `/selections/${data.id}/candidats/list`,
            search: `?selectionName=${data.selectionName}&quantity=${selectedCandidats.length}&consultant=${selectionData.creator.value}`
          })
        })
        .catch(({ response }) => {
          snackbarShowMessage(response.data.error, "error", 4000);
        })
        .finally(() => {
          handleClose();
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (modalState.open) {
      updateObjectStateValue(setSelectionData, {
        selectionName: isModify ? oldSelectionName : "",
        creator: isModify ? oldCreator : loggedUser,
        mission: isModify ? oldMission : "",
      });
      updateObjectStateValue(setCreatorData, { isLoading: true });
      updateObjectStateValue(setMissionData, { isLoading: true });
      getUsersByPairs()
        .then(({ data }) => {
          updateObjectStateValue(setCreatorData, { isLoading: false, data });
        })
        .catch((error) => {
          updateObjectStateValue(setCreatorData, { isLoading: false });
          snackbarShowMessage(
            "Erreur de chargements des utilisateurs.",
            "error",
            4000
          );
        });
      getMissionsActive()
        .then(({ data }) => {
          updateObjectStateValue(setMissionData, { isLoading: false, data });
        })
        .catch((error) => {
          updateObjectStateValue(setMissionData, { isLoading: false });
          snackbarShowMessage(
            "Erreur de chargements des missions actives.",
            "error",
            4000
          );
        });
    }
    return () => {
      setErrors({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.open]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="modal-header">
        <h4 className={classes.modalTitle}>Crée une sélection</h4>
      </div>
      <div className="modal-body">
        <label className="input-label" htmlFor="nomSelection">
          NOM DE LA SÉLECTION <span className="required-input">*</span>
        </label>
        <TextField
          name="selectionName"
          id="nomSelection"
          onChange={handleChange}
          placeholder="Nom de la séléction."
          margin="normal"
          variant="outlined"
          autoComplete="off"
          fullWidth
          value={selectionData.selectionName}
          {...(errors.nomSelection && {
            error: true,
            label: errors.nomSelection,
          })}
        />
        <CustomAutoComplete
          listData={creatorData}
          errors={errors}
          value={selectionData.creator}
          handleDataChange={handleChangeUser}
          name="sourceur"
          disableClearable={true}
        />
        <CustomAutoComplete
          listData={missionData}
          errors={errors}
          value={selectionData.mission}
          handleDataChange={handleChangeMission}
          name="mission"
        />
      </div>
      <div className="modal-footer">
        <Button
          className={classes.closeButton}
          onClick={handleClose}
          disabled={loading}
        >
          Annuler
        </Button>
        <Button
          className={classes.saveButton}
          onClick={() => (isModify ? handleUpdate() : handleSubmit())}
          disabled={loading}
        >
          Crée
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {children(handleOpen)}
      <Modal
        className={classes.modal}
        open={modalState.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalState.open}>{body}</Fade>
      </Modal>
    </>
  );
};

export default withRouter(SelectionModal);
