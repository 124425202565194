import {
    Box, CircularProgress, Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAllCandidatsForfMission } from "../Services/CandidatService";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { withSnackbar } from "../../utils/SnackbarHOC";
import "./CandidatsListInMission.css"
import { useHistory } from 'react-router-dom'
import { getDetails } from "../Services/MissionService";
import Loader from "../../components/Loader/Loader";
import { useMediaQuery } from "@material-ui/core";
import { theme } from "../../utils/Theme";
import { Button } from "@material-ui/core";
import { Add, Remove, RemoveRedEye } from "@mui/icons-material";
import { IconButton } from "@material-ui/core";
import { useEffect } from "react";
import { updateObjectStateValue, useQuery } from "../../utils/FunctionHelpers";
import { handleOnViewColumnsChange, displayColumn } from "../../utils/viewColumns";
import { displaySwipeableDrawerSummaryCandidat } from "../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch } from "react-redux";
import ShowAddCvCandidat from "../Candidat/VisualiserCandidat/ShowAddCvCandidat";
import AppointmentModal from "../Candidat/VisualiserCandidat/Tabs/Modals/CandidatProcess/AppointmentModal";


const CandidatsListInMission = ({ snackbarShowMessage }) => {
    const [reloadData, setReloadData] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const [candidats, setCandidats] = useState([]);
    const { idMission } = useParams();
    const [candidatsInactifs, setCandidatsInactifs] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch()
    const [missionData, setMissionData] = useState({
        mission: {},
        isLoading: true
    })
    let query = useQuery();

    const filterAppointmentsByStatut = (appointments, matchString) => {
        return appointments?.find(({ status }) => new RegExp(matchString).test(status));
    }

    const viewCandidat = (candidatId, tabIndex) => {
        let candidatsIds = [];

        candidats.forEach((candidat) => {
          candidatsIds.push(candidat.id);
        });

        history.push({
          pathname: `/candidats/${candidatId}/view`,
          state: {
            tabIndex: tabIndex,
            candidatsInList: candidatsIds,
            candidatIndex: candidatsIds.indexOf(candidatId),
            numbersTotalOfSelections: candidats.length
          },
        });
    };

    React.useEffect(() => {
        let active = true;

        if (idMission === "undefined") {
            history.push({
                pathname: "/missions",
            });
        }

        (async () => {
            setMissionData({
                isLoading: true,
                mission: {}
            });

            try {
                const [missionResponse, candidatsResponse] = await Promise.all([
                    getDetails(idMission),
                    getAllCandidatsForfMission(idMission, candidatsInactifs)
                ]);

                if (!active) {
                    return;
                }
                updateObjectStateValue(setMissionData, {
                    isLoading: false,
                    mission: missionResponse.data
                });
                setCandidats(candidatsResponse.data.candidats);
            } catch (error) {
                if (!active) {
                    return;
                }

                snackbarShowMessage("Erreur de chargement de données.", "error", 4000);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadData]);

    const getData = async () => {
        try {
            setCandidatsInactifs(!candidatsInactifs)
            setCandidats([])
            setLoadingData(true)
            const candidatsResponse = await getAllCandidatsForfMission(idMission, !candidatsInactifs);
            setCandidats(candidatsResponse.data.candidats);
        } catch (error) {
            snackbarShowMessage("Erreur de chargement de données.", "error", 4000);
        } finally {
            setLoadingData(false);
        }
    };

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isSmall = useMediaQuery(theme.breakpoints.between(0, 767));
    const isMediumMode = useMediaQuery(
        theme.breakpoints.between(767, 1023)
    );

    const isLargeMode = useMediaQuery(
        theme.breakpoints.between(1023, 2800)
    );

    const tableBodyMaxHeight = `${isSmall
        ? viewportHeight - 292
        : isMediumMode
            ? viewportHeight - 358
            : isLargeMode
                ? viewportHeight - 300
                : 200}px`;

    const tableName = "candidatsListInMission";

    const columns = [
        { name: "id", label: "ID ", options: { filter: true, sort: true, display: false, } },
        {
            name: "numCandidat", label: "N°",
            options: {
                display: displayColumn("numCandidat", tableName),
                filter: true, sort: true,
                customBodyRender: (value, tableMeta) => {
                    const uploadedFiles = tableMeta.rowData[tableMeta.rowData.length - 1] || [];
                    return (
                        <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "6rem" }}>
                            <ShowAddCvCandidat
                                {...{ snackbarShowMessage, files: uploadedFiles }}
                            >
                                {value}
                            </ShowAddCvCandidat>
                        </Box>
                    );
                },
            }
        },
        {
            name: "nom", label: "NOM",
            options: {
                display: displayColumn("nom", tableName),
                filter: true, sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "200px" }}>
                            {!(isSmall || isMediumMode) && <IconButton
                                onClick={() => dispatch(displaySwipeableDrawerSummaryCandidat({ show: true, id: tableMeta.rowData[0] }))}
                                data-toggle="tooltip"
                                title="Voir resumé">
                                <RemoveRedEye size={"15px"} /></IconButton>}
                            <Link
                                to={{ pathname: `/candidats/${tableMeta.rowData[0]}/view` }}
                                onClick={(e)=>{
                                    e.preventDefault()
                                    viewCandidat(tableMeta.rowData[0], 0)
                                }}
                            >{value}</Link>
                        </Box>
                    );
                },
            }
        },
        { name: "consultant", label: "TALENT MANAGER",  options: {
                display: displayColumn("sourceur", tableName),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "150px" }}>
                                {value ?? "-"}
                            </Box>
                        </>
                    );
                },
            },
        },
        { name: "sourceur", label: "SOURCEUR",  options: {
                display: displayColumn("sourceur", tableName),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "150px" }}>
                                {value ?? "-"}
                            </Box>
                        </>
                    );
                },
            },
        },
     /*   {
            name: "statut", label: "STEP",
            options: {
                setCellProps: () => ({ style: {} }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <AppointmentsListModal {...{ appointments: tableMeta.rowData[13], step: value, filterAppointmentsByStatut }} />
                    );
                },
            },
        },*/
        {
            name: "dateAccrochage",
            label: "PREQUALIF RH",
            options: {
                display: displayColumn("dateAccrochage", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "rvTech",
            label: "TEST INTERNE",
            options: {
                display: displayColumn("rvTech", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },

        {
            name: "sent",
            label: "ENVOI CLIENT",
            options: {
                display: displayColumn("sent", tableName),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "5rem" }}>
                                {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            </Box>
                        </>);
                },
            },
        },
        {
            name: "rvClient",
            label: "RV CLIENT",
            options: {
                display: displayColumn("rvClient", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "rvClosing",
            label: "RV CLOSING",
            options: {
                display: displayColumn("rvClosing", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        /*{
            name: "lastAppointmentDatePropale",
            label: "PROPALE",
            options: {
                display: displayColumn("lastAppointmentDatePropale", tableName),
                customBodyRender: (value, tableMeta, updateValue) => {
                    const formattedDate = value && moment(new Date(value)).isValid()
                        ? moment(new Date(value)).format("DD/MM/YYYY")
                        : "-";

                    return (
                        <>
                            <Box data-toggle="tooltip" title={formattedDate} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "100px" }}>
                                {formattedDate}
                            </Box>
                        </>
                    );
                },
            },
        }*/
        {
            name: "lastAppointmentDateIntegration",
            label: "EMB/INTé",
            options: {
                display: displayColumn("lastAppointmentDateIntegration", tableName),
                customBodyRender: (value, tableMeta, updateValue) => {
                    const formattedDate = value && moment(new Date(value)).isValid()
                        ? moment(new Date(value)).format("DD/MM/YYYY")
                        : "-";
                    return (
                        <>
                            <Box data-toggle="tooltip" title={formattedDate} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "100px" }}>
                                {formattedDate}
                            </Box>
                        </>);
                },
            },
        },
        {
            name: "actions",
            label: "ISSUE",
            options: {
                display: displayColumn("actions", tableName),
                setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
                setCellProps: () => ({ style: { padding: "0px 12px" } }),
                customBodyRender: (value, tableMeta) => {
                    const processId = tableMeta.rowData[14];


                    return (
                        <Box>
                            <AppointmentModal
                                processId={processId}
                                reloadData={reloadData}
                                setReloadData={setReloadData}
                                snackbarShowMessage={snackbarShowMessage}
                            />
                        </Box>
                    );
                },
            },
        },
        { name: "uuid", label: "N°MISSION", options: { filter: true, sort: true, display: false } },
        { name: "appointments", options: { filter: true, sort: true, display: false, } },
        { name: "idProcess", options: { filter: true, sort: true, display: false, } },
        { name: "uploadedFiles", options: { filter: true, sort: true, display: false, viewColumns: false } }
    ];
    const options = {
        tableBodyMaxHeight: tableBodyMaxHeight,
        sort: true,
        textLabels: {
            body: {
                noMatch: loadingData ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
            },
        },
        selectableRows: "none",
        draggableColumns: {
            enabled: true,
        },
        onViewColumnsChange: (columnName, action) => {
          handleOnViewColumnsChange(columnName, action, tableName);
        },
        responsive: "standard"
    };


    const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

    const extractDate = (appointments, statusRegex) => {
        const appointment = filterAppointmentsByStatut(appointments, statusRegex);
        return appointment ? moment(appointment.date).toDate().getTime() : null;
    };
    const gridData = candidats.map((row) => {
        const updatedRow = {
            ...row,
            sent: (row.sent?.date != null) ? moment(row?.sent?.date).toDate().getTime() : null,
            dateAccrochage:(row.dateAccrochage?.date != null) ? moment(row?.dateAccrochage?.date).toDate().getTime() : null,
            rvClient: (row.rvClient?.date != null) ? moment(row?.rvClient?.date).toDate().getTime() : null,
            rvTech:(row.rvTech?.date != null) ? moment(row?.rvTech?.date).toDate().getTime() : null,
            rvClosing:(row.rvClosing?.date != null) ? moment(row?.rvClosing?.date).toDate().getTime() : null,
            revealed: (row.revealed?.date != null) ? moment(row?.revealed?.date).toDate().getTime() : null,
            integration: extractDate(row?.appointements, /Embauche\/Integration|intégration/),
            propale: extractDate(row?.appointments, /Propale/),
            e1: extractDate(row?.appointments, /RDV 1|E1/),
            e2: extractDate(row?.appointments, /RDV 2|E2/),
            e3: extractDate(row?.appointments, /RDV 3|E3/),
        };
        return updatedRow;
    });

    return (
        <>
            {loading
                ?
                <Loader />
                :
                <Box sx={{ padding: "0 20px" }}>
                    <Box className="candidatedit_header">
                        <Grid container spacing={1} style={{ paddingBottom: "5px" }}>
                            <Grid item xs={10} sm={8} md={8} xl={6}>
                                <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                                    <h4 className="text-capitalize" style={{ color: "rgb(17, 122, 167)" }}>
                                        MISSION: <Link to={{ pathname: `/societe/${missionData.mission.societeId}/missions/${missionData.mission.uuid}/view` }} className="d-print-none font-lg" style={{ textDecoration: "none" }}>{"N°" + missionData.mission.numMission}</Link>
                                    </h4>
                                    <div className="text-capitalize" style={{ color: "rgb(17, 122, 167)", fontSize: "20px" }}>
                                        CONSULTANT: {missionData.mission.consultant}
                                    </div>
                                    <div className="text-capitalize" style={{ color: "rgb(17, 122, 167)", fontSize: "20px" }}>
                                        CONTACT: {missionData.mission.contact}
                                    </div>
                                    <div className="text-capitalize" style={{ color: "rgb(17, 122, 167)", fontSize: "20px" }}>
                                        {missionData.mission.nom_mission + " - " + missionData.mission.nom_societe} - {query.get('quantity')}
                                    </div>
                                    <div style={{ color: "rgb(17, 122, 167)", fontSize: "16px" }}>
                                        {missionData.mission.datecreation ? "Créée le " + moment(missionData.mission.datecreation.date).format('DD MMMM YYYY') : ""}
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4} xl={6}>
                                <Box sx={{ display: "flex", justifyContent: "end" }}>
                                    {!isSmMode ? <Button
                                        style={{ backgroundColor: "rgb(17, 122, 167)", color: "white", margin: "10px 0", fontWeight: 500 }}
                                        startIcon={!candidatsInactifs ? <Add /> : <Remove />}

                                        onClick={getData}
                                    >
                                        CANDIDATS INACTIFS
                                    </Button>
                                        : <IconButton data-toggle="tooltip"
                                            title={!candidatsInactifs ? "Ajouter" : "Retirer"} onClick={getData}>
                                            {!candidatsInactifs ? <Add /> : <Remove />}
                                        </IconButton>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <MUIDataTable title={"CANDIDATS"} data={gridData} columns={columns} options={options} />
                    </Box>
                </Box >
            }
        </>
    );
}
export default withSnackbar(CandidatsListInMission);
