import { Box, Button, CircularProgress, createTheme, MuiThemeProvider, useMediaQuery } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const missionListStyle = createTheme({
    overrides: {
        MUIDataTableToolbar: {
            root: {
                display: 'none'
            }
        }
    }
});

const MissionsTable = ({ ...props }) => {
    const columns = [
        {
            name: "uuid",
            label: "N°",
            options: {
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>
                                <Link to={{ pathname: `/societe/${tableMeta.rowData[10]}/missions/${tableMeta.rowData[0]}/view` }} className="d-print-none font-sm" style={{ textDecoration: "none" }}>{value}</Link>
                            </Box>
                        </>
                    );
                },
            },
        },
        {
            name: "numMission",
            label: "N°",
            options: {

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "3rem" }}>
                                <Link to={{ pathname: `/societe/${tableMeta.rowData[14]}/missions/${tableMeta.rowData[0]}/view` }} className="d-print-none font-sm" style={{ textDecoration: "none" }}>{value}</Link>
                            </Box>
                        </>
                    );
                },
            },
        },
        {
            name: "client",
            label: "CLIENT",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>
                                <Link to={{ pathname: `/societe/${tableMeta.rowData[13]}/view` }} className="d-print-none font-sm" style={{ textDecoration: "none" }}>{value ? value : ""}</Link>
                            </Box>
                        </>
                    );
                },
            },
        },
        {
            name: "nom_mission",
            label: "NOM MISSION",
            options: {
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "270px" }}>
                                <Link to={{ pathname: `/societe/${tableMeta.rowData[14]}/missions/${tableMeta.rowData[0]}/view` }} className="d-print-none font-sm" style={{ textDecoration: "none" }}>{value}</Link>
                            </Box>
                        </>
                    );
                },
            },
        },
        {
            name: "localisation",
            label: "LOCALISATION",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const displayValue = value === null || value === undefined || value === "" ? "-" : value;
                    return (
                        <Box data-toggle="tooltip" title={displayValue} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "150px" }}>
                            {displayValue}
                        </Box>
                    );
                },
            },
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const displayValue = value === null || value === undefined || value === "" ? "-" : value;
                    return (
                        <Box data-toggle="tooltip" title={displayValue} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "150px" }}>
                            {displayValue}
                        </Box>
                    );
                },
            },
        },
        {
            name: "priorite",
            label: "PRIORITE",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "150px" }}>
                                {value ?? "-"}
                            </Box>
                        </>
                    );
                },
            },
        },
        {
            name: "createdAt",
            label: "DATE DEBUT",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const validDate = moment(value?.date);
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={validDate.isValid() ? validDate.format('DD/MM/YYYY') : "Invalid date"}
                            style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "150px" }}
                        >
                            {validDate.isValid() ? validDate.format('DD/MM/YYYY') : "-"}
                        </Box>
                    );
                },
                sortCompare: (order) => {
                    return (obj1, obj2) => {
                        const date1 = moment(obj1.data.date);
                        const date2 = moment(obj2.data.date);

                        if (!date1.isValid() && !date2.isValid()) return 0;
                        if (!date1.isValid()) return order === 'asc' ? -1 : 1;
                        if (!date2.isValid()) return order === 'asc' ? 1 : -1;

                        return (date1 - date2) * (order === 'asc' ? 1 : -1);
                    };
                }
            },
        },
        {
            name: "contact",
            label: "CONTACT",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (

                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>
                                <Link to={{ pathname: `/societe/${tableMeta.rowData[15]}/contacts/${tableMeta.rowData[16]}/view` }}>{value}</Link>
                            </Box>
                        </>
                    );
                },
            },
        },
        {
            name: "contactId",
            label: "ID DE LA CONTACT",
            options: {
                display: false,
            },
        },
        {
            name: "consultant",
            label: "TALENT MANAGER",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "150px" }}>
                                {value ?? "-"}
                            </Box>
                        </>
                    );
                },
            },
        },
        {
            name: "competence_sectorielle",
            label: "COMPÉTENCES SECTORIELLES",
            options: {
                display: false,
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } })
            },
        },
        {
            name: "competence_metier",
            label: "COMPÉTENCES MÉTIERS",
            options: {
                display: false,
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } })
            },
        },
        {
            name: "accountManager",
            label: "ACCOUNT MANAGER",
            options: {
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
            },
        },
        {
            name: "CV",
            label: "CV EN COURS",
            options: {
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px", display: "flex", justifyContent: "center" }}>
                            <Link to={{
                                pathname: `/candidats/missions/${tableMeta.rowData[0]}/list`,
                                search: `?quantity=${value}`,
                            }}>{value}</Link>
                        </Box>
                    );
                },
            },
        },
        {
            name: "societeId",
            label: "ID DE LA SOCIETE",
            options: {
                display: false,
            },
        },
        {
            name: "contactId",
            label: "ACCOUNT MANAGER",
            options: {
                display: false,
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } })
            },
        },
        {
            name: "Action",
            label: "ACTION",
            options: {
                customBodyRender: (data, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ width: "2rem" }}>
                            <Button data-toggle="tooltip"
                                title="Modifier" onClick={() => props.editMission(tableMeta.rowData[0], tableMeta.rowData[10])} style={{ color: "#C8D148" }}>
                                <Edit />
                            </Button>
                        </Box>
                    );
                },
                display: false
            },
        },
    ];

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
    const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));
    const isLargeMode = useMediaQuery((theme) => theme.breakpoints.between(1023, 2800));

    const tableBodyMaxHeight = `${isSmall ? viewportHeight - 292 : isMediumMode ? viewportHeight - 340 : isLargeMode ? viewportHeight - 310 : 200}px`;

    const options = {
        tableBodyMaxHeight: tableBodyMaxHeight,
        rowsPerPage: 50,
        textLabels: {
            body: {
                noMatch: props.missionData.isLoading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
            },
        },
        selectableRows: "none",
        draggableColumns: {
            enabled: true,
        },
        filter: true,
        filterType: "dropdown",
        responsive: "standard"
    };
    return (
        <>
            <MuiThemeProvider theme={missionListStyle}>
                <MUIDataTable title={"Liste des missions"} data={props.missionData.data} columns={columns} options={options} />
            </MuiThemeProvider>
        </>
    )

}

export default MissionsTable;
