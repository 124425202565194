import {Card, Grid} from "@mui/material";
import {Table, TableBody, TableCell, TableContainer, TableRow, useMediaQuery} from "@material-ui/core";
import {v4} from "uuid";
import moment from "moment";
import {useStyles} from "@material-ui/pickers/views/Year/Year";
import {theme} from "../../../../utils/Theme";
import {useEffect, useState} from "react";

export default function PostRecherche({
                                        actuellePackage,
                                        actuelleFixe,
                                        actuelleVariable,
                                        actuelleInteressement,
                                        actuelleAutres,
                                        souhaiteAutres,
                                        souhaiteTjm,
                                        souhaiteFixe,
                                        souhaiteInteressement,
                                        souhaitePackage,
                                        souhaiteVariable,
                                        dispoLe,
                                        preAvis,
                                      }) {

  const classes = useStyles();
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const [isAdmin, setIsAdmin] = useState(false)
  function getUserData() {
    return JSON.parse(localStorage.getItem("userData"));
  }
  const checkAdmin = () => {
    const userRole = getUserData()?.role;
    if (userRole && userRole.includes('ROLE_DIRECTION')) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    checkAdmin();
  }, []);


  const sections = [
    {
      title: "DISPONIBILITÉ",
      rows: [
        { label: "PRÉAVIS", value: preAvis },
        { label: "DATE DE DISPONIBILITÉ", value: !(['null', null, ''].includes(dispoLe)) ? moment(dispoLe).format("DD/MM/yyyy") : "-" },
      ],
    },
    {
      title: "RÉMUNÉRATION ACTUELLE",
      rows: [
        { label: "PACKAGE", value: actuellePackage, unit: "MAD" },
        { label: "FIXE", value: actuelleFixe, unit: "MAD" },
        { label: "VARIABLE", value: actuelleVariable, unit: "MAD" },
       // { label: "INTÉRESSEMENT/PARTICIPATION", value: actuelleInteressement },
        { label: "AUTRES", value: actuelleAutres, unit: "MAD" },
      ],
    },
    {
      title: "RÉMUNÉRATION SOUHAITÉE",
      rows: [
        { label: "PACKAGE", value: souhaitePackage, unit: "MAD" },
        { label: "FIXE", value: souhaiteFixe, unit: "MAD" },
        { label: "VARIABLE", value: souhaiteVariable, unit: "MAD" },
        { label: "AUTRES", value: souhaiteAutres, unit: "MAD" },
          { label: "TJM", value: souhaiteTjm, unit: "MAD" },
      ],
    },

  ].filter(section => isAdmin || section.title !== "RÉMUNÉRATION ACTUELLE" && section.title !== "RÉMUNÉRATION SOUHAITÉE");

  return (
      <Grid container spacing={2} style={!isSmMode ? { padding: "1em" } : {}}>
        {sections.map((section, index) => (
            <Grid
                key={v4()}
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
            >
              <Card>
                <h3 className={classes.tabTitle}>{section.title}</h3>
                <TableContainer>
                  <Table className="table table-striped">
                    <TableBody component="tbody">
                      {section.rows.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                            <TableCell component="td" scope="row" style={{ fontSize: "0.8rem" }}>
                              <strong>{row.label}</strong>
                            </TableCell>
                            <TableCell align="right" style={{ fontSize: "0.8rem" }}>
                              {row.value !== null ? `${row.value} ${row.unit || ""}` : "-"}
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
        ))}
      </Grid>
  );
}
