import { Backdrop, Button, Fade, makeStyles, Modal } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { updateObjectStateValue } from "../../../utils/FunctionHelpers";
import {
    addremovecandidattoselection,
    getAllCadidatSelections
} from "../../Services/SelectionCandidatService";
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

function getModalStyle() {
    const top = 0;
    const left = 0;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: "20px",
    },
    saveButton: {
        backgroundColor: "rgb(66, 186, 150)",
        color: "#FFF",
        fontWeight: 500,
        "&:hover, &:focus": {
            backgroundColor: "#389e7f",
            borderColor: "#359478",
        },
    },
    deleteButton: {
        color: "#FFF",
        backgroundColor: "rgb(239, 98, 98)",
        fontWeight: 500,
        marginLeft: "0.5em",
        "&:hover, &:focus": {
            backgroundColor: "rgb(220, 98, 98)",
            borderColor: "rgb(239, 98, 98)",
        },
    },
    plus: {
        fontWeight: 500,
        marginBottom: "0.2em",
    },
    buttonLabel: {
        paddingLeft: "0.4em",
        marginLeft: 0,
    },
    closeButton: {
        color: "#1b2a4e",
        backgroundColor: "#d9e2ef",
        borderColor: "#d9e2ef",
        marginLeft: "0.5em",
        fontWeight: "500",
    },
    modalTitle: {
        color: "rgb(17 122 167)",
    },
    buttonProgress: {
        color: "white",
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ChooseMultipleSelectionModal = ({ children, selectedCandidats, snackbarShowMessage, reloadSelections, setReloadSelections, currentSelection }) => {
    const [openModal, setOpenModal] = useState(false)
    const classes = useStyles()
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isAddingSelection, setIsAddingSelection] = useState(false);
    const [selectionData, setSelectionData] = useState({
        isLoading: false,
        data: [],
        label: "SELECTION",
        required: true,
        selection: null,
        errors: {}
    })

    const loadSelections = () => {
        updateObjectStateValue(
            setSelectionData,
            { isLoading: true, data: [] }
        )
        getAllCadidatSelections('?archived=false').then(({ data }) => {
            updateObjectStateValue(
                setSelectionData,
                { isLoading: false, data: data.candidatsSelections }
            )
        }).catch(() => {
            snackbarShowMessage("Erreur de chargement des sélections.", "error", 4000)
        }).finally(() => {
            updateObjectStateValue(
                setSelectionData,
                { isLoading: false }
            )
        })
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleOpen = () => {
        setOpenModal(true)
    }
    const handleAddSelection = async () => {
        snackbarShowMessage("Ajout aux selections en cours....", "warning", "4000")
        setIsAddingSelection(true);
        try {
            let ids = selectedOptions.map((option) => option.id)
            await addremovecandidattoselection({selectedSelections: ids}, selectedCandidats)
            snackbarShowMessage("Ajout aux selections termine avec success", "success", "4000")
        } catch (e) {
            snackbarShowMessage("Error Occured while Adding Selections", "danger", "4000")
        } finally {
            setReloadSelections(!reloadSelections)
            setIsAddingSelection(false);
        }
    }
    useEffect(() => {
        if (currentSelection) {
            const selectedOption = selectionData.data.find(option => option.id === currentSelection);
            if (selectedOption) {
                setSelectedOptions([selectedOption]);
            }
        }
    }, [currentSelection, selectionData.data]);

    useEffect(() => {
        if (openModal) loadSelections()
        return () => {
            setSelectionData({
                isLoading: false,
                data: [],
                label: "SELECTION",
                required: true,
                selection: null,
                errors: {}
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal])


    const body = (
        <div style={getModalStyle} className={classes.paper}>
            <div className="modal-header">
                <h4 className={classes.modalTitle}>Ajouter à une Sélection existante</h4>
            </div>
            <div className="modal-body">
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={selectionData.isLoading ? [] : selectionData.data}
                    loading={selectionData.isLoading}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.selectionName || "Unknown"}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.selectionName}
                        </li>
                    )}
                    value={selectedOptions}
                    onChange={(event, newValue) => setSelectedOptions(newValue)}
                    renderInput={(params) => (
                        <TextField {...params} label="Sélections" placeholder="Sélections" />
                    )}
                />
            </div>
            <div className="modal-footer">
                <Button className={classes.saveButton} disabled={selectionData.isLoading || isAddingSelection} onClick={handleAddSelection}>
                    Ajouter a la sélection
                </Button>
                <Button className={classes.closeButton} onClick={handleClose}>
                    Annuler
                </Button>
            </div>
        </div>
    );

    return (
        <>
            {children(handleOpen)}
            <Modal
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>{body}</Fade>
            </Modal>
        </>
    );
}
export default ChooseMultipleSelectionModal
