import api from "../../utils/API";

const getPilotageConsultants = (queryParams) => {
    return api.get(`/gestion/pilotage/consultants/list${queryParams}`);
}
const getPilotageClients = (queryParams) => {
    return api.get(`/gestion/pilotage/clients/list${queryParams}`);
}
const getPilotageCommercial = (queryParams) => {
    return api.get(`/gestion/pilotage/commercial/list${queryParams}`);
}
const exportPilotageCommercial = (startDate, endDate) => {
    return api.get(`/gestion/pilotage/commercial/export`,
        {
            params: {startDate, endDate},
            responseType: "blob",
        })
}

export { getPilotageConsultants, getPilotageClients, getPilotageCommercial, exportPilotageCommercial }
