
export const removeLineBreak = (value) => {
  if (value != null) {
    const result = value?.replace(/<\/?p[^>]*>/g, '<p style="margin-block:1px !important; font-size: 14px;line-height: 18px;">');
    const duplicate = result.replace(/(<p><br><\/p>)+/g, "$1")
    const regex = /(<span[^>]*style="[^"]*?)(background-color: rgba\(0, 0, 0, 0.04\);)([^"]*?"[^>]*>)/g;
    const updatedHtmlText = duplicate?.replace(regex, "$1background-color: transparent;$3");
    return updatedHtmlText
  }
  return value
}