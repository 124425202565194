import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import DownloadButton from "./DownloadCv";

const PdfPreview = ({ fileUrl, fileName }) => {
    const renderToolbar = (Toolbar) => {
        return (
            <Toolbar>
                {(slots) => {
                    return <></>;
                }}
            </Toolbar>
        );
    };

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
    });

    return (
        <div style={{ height: "600px", border: "1px solid #e4e4e4" }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
                <Viewer
                    fileUrl={fileUrl}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </Worker>
            <DownloadButton fileUrl={fileUrl} fileName={fileName} style={{textAlign: "center"}}/>
        </div>
    );
};

export default PdfPreview;
