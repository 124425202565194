import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IoIosPersonAdd } from "react-icons/io";
import { BsPeopleFill } from "react-icons/bs";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SelectionModal from "../Modals/SelectionModal";
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import "../dashboardStyle.css";
import {
  makeStyles
} from "@material-ui/core";
import { withSnackbar } from "../../../utils/SnackbarHOC";
import { IoArchiveSharp } from "react-icons/io5";
import { getUserData } from "../../../utils/FunctionHelpers";
import ChooseSelectionModal from "../Modals/ChooseSelectionModal";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { Mail, MoveToInbox } from "@material-ui/icons";
import { setToggleMailing } from "../../../redux/toggle/actions/toggleActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";


const useStyles = makeStyles(() => ({
  fontStyle: {
    fontFamily: "myFont",
    fontSize: "15px",
    width: "100%"
  },
}));

const ButtonList = ({ history, selectedCandidats, snackbarShowMessage, loading }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const selectedCandidatForSelection = useSelector(
    (state) => state.candidats.candidatsSelectedForSelection
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectionModalProps = { selectedCandidats, closeList: handleClose, isModify: false, snackbarShowMessage, history };
  const classes = useStyles();
  const handleOperation = (action) => {
    switch (action) {
      case "candidat":
        history.push({
          pathname: "/candidats/add",
        });
        break;
      case "societe":
        history.push({
          pathname: "/societe/add",
        });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Button onClick={handleClick} className={classes.fontStyle} style={{ color: "#117aa7" }}
      >
        <PlaylistAdd />
        Opérations
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleOperation("candidat")}>
          <ListItemIcon>
            <IoIosPersonAdd fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Ajouter un candidat" />
        </MenuItem>
        <MenuItem onClick={() => handleOperation("societe")}>
          <ListItemIcon>
            <BsPeopleFill fontSize="large" />
          </ListItemIcon>
          <ListItemText primary="Ajouter une société" />
        </MenuItem>
        <MenuItem onClick={() => dispatch(setToggleMailing(true))} disabled={selectedCandidatForSelection.length > 0 ? false : true}>
          <ListItemIcon>
            <Mail fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Emailing" />
        </MenuItem>
        {getUserData().role.includes('ROLE_DIRECTION') && (<>
          <SelectionModal {...selectionModalProps} >
            {(handleOpen) => (
              <MenuItem onClick={() => handleOpen()} disabled={!(selectedCandidats.length > 0) ? true : false}>
                <ListItemIcon>
                  <IoArchiveSharp fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Enregistrer la séléction" />
              </MenuItem>
            )}
          </SelectionModal>
          <ChooseSelectionModal {...selectionModalProps} >
            {(handleOpen) => (
              <MenuItem onClick={() => handleOpen()} disabled={!(selectedCandidats.length > 0) ? true : false}>
                <ListItemIcon>
                  <MoveToInbox fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Affiner et ajouter à une Sélection existante" />
              </MenuItem>
            )}
          </ChooseSelectionModal>
        </>)
        }
      </Menu>
    </div >
  );
};

export default withRouter(withSnackbar(ButtonList));
