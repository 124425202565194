import api from "../../utils/API";

const getRole = () => {
  return api.get(`/usermanager/userrole/list`);
};
const getAll = (page) => {
  return api.get(`/usermanager/users/list`);
};

const getAllUsersForTasks = () => {
  return api.get(`/usermanager/usersfortasks/list`);
};

const getDetails = (uuid) => {
  return api.get(`/usermanager/user/${uuid}/details`);
};
const addUser = (data) => {
  return api.post(`/user/add`, data);
};
const editUser = (data, uuid) => {
  return api.post(`/user/${uuid}/update/`, data);
};
const deleteUser = (uuid) => {
  return api.delete(`/user/${uuid}/delete`);
};
const getConsultantsAndManagers = (query = '') => {
  return api.get(`/usermanager/consultantsandmanagers/list${query}`);
};
const getTalentManagers = (query = '') => {
  return api.get(`/usermanager/talentmanagers/list${query}`);
}
const getUsersByPairs = () => {
  return api.get(`/usermanager/users/pairs/list`);
};

const archiveUser = (data) => {
  return api.put('/usermanager/user/status/update', data);
}

export {getAll, getDetails, editUser, deleteUser, addUser, getRole, getConsultantsAndManagers, getAllUsersForTasks, getUsersByPairs, archiveUser, getTalentManagers};
