import React, { useEffect, useRef, useState } from 'react'

import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, Box, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { getCvBlob } from '../../Services/FileService';
import { Description, NoteAdd } from '@material-ui/icons';
import LoadingButton from '@mui/lab/LoadingButton';
import { Fade } from '@material-ui/core';
import { withSnackbar } from '../../../utils/SnackbarHOC';
import { addCvCandidat } from '../../Services/CandidatService';
import {Tooltip} from "@mui/material";
import PdfPreview from "./PDFPreview";
import DownloadButton from "./DownloadCv";


function getModalStyle() {
    const top = 0;
    const left = 0;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: "20px",
        width: "60%",
        height: "95%",
    },
    saveButton: {
        backgroundColor: "rgb(66, 186, 150)",
        color: "#FFF",
        fontWeight: 500,
        "&:hover, &:focus": {
            backgroundColor: "#389e7f",
            borderColor: "#359478",
        },
    },
    target: {
        fontWeight: 500,
        marginBottom: "0.2em",
    },
    buttonLabel: {
        marginLeft: 0,
    },
    closeButton: {
        color: "#1b2a4e",
        backgroundColor: "#d9e2ef",
        borderColor: "#d9e2ef",
        marginRight: "0.5em",
        fontWeight: "500",
    },
    actionBar: {
        marginTop: "1.5em",
    },
    modalTitle: {
        color: "rgb(17 122 167)",
        marginBottom: "1em",
    },
    buttonCustom: {
        marginLeft: "1em",
        float: "right",
        color: "#117aa7",
        padding: "6px 16px"
    },
    buttonStyle: {
        color: "#2196f3",
        paddingLeft: "0px"
    }
}));

function ShowCvCandidat({ children, snackbarShowMessage, files, idCandidat, editMode = false, reloadData, setReloadData, ...otherProps }) {
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingCV, setLoadingCV] = useState(false);
    const classes = useStyles();
    const [iframe, setIframe] = useState('');
    const [isCVFormated, setIsCVFormated] = useState(null);
    const searchCv = files?.filter((cv) => cv.categorie === "cv")
    const [cvUpload, setCvUpload] = useState(null);
    const uploadRef = useRef(null);

    const resetData = () => {
        setCvUpload(null);
        setIsCVFormated(null)
    }

    useEffect(() => {
        const sendCv = async () => {
            if (cvUpload) {
                setLoadingCV(true)
                const formData = new FormData();
                formData.append('cv', cvUpload);
                formData.append('idCandidat', idCandidat);
                formData.append('type', isCVFormated ? 'cvFormate' : 'cv');
                try {
                    snackbarShowMessage('Ajoutant le CV, patientez svp!', 'warning', 4000);
                    const response = await addCvCandidat(formData);
                    snackbarShowMessage('CV ajouté!', 'success', 4000);
                    setReloadData(!reloadData);
                } catch (e) {
                    snackbarShowMessage("Erreur d'ajout de CV.", 'error', 4000);
                } finally {
                    resetData();
                    setLoadingCV(false);
                }
            }
        };

        sendCv();
    }, [cvUpload]);

    const handleShowButton = () => {
        return (
            <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                {searchCv?.length > 0 ? (
                    <LoadingButton
                        data-toggle="tooltip"
                        title={"Ouvrir CV."}
                        className={classes.buttonStyle}
                        onClick={handleOpen}
                        variant="text"
                        size="medium"
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<Description />}
                    >
                        <span className={classes.buttonLabel}>{children ?? ""}</span>
                    </LoadingButton>
                ) : (
                    <span className={classes.buttonLabel}>{children ?? ""}</span>
                )}
                {!editMode &&
                    [
                        {
                            tooltip: "Ajouter un CV",
                            isCVFormated: false,
                            color: "primary",
                        },
                        {
                            tooltip: "Ajouter un CV Formaté",
                            isCVFormated: true,
                            color: "action",
                        },
                    ].map((button, index) => (
                        <Tooltip key={index} title={button.tooltip}>
                        <span>
                            <LoadingButton
                                onClick={() => {
                                    setIsCVFormated(button.isCVFormated);
                                    uploadRef.current.click();
                                }}
                                variant="text"
                                size="medium"
                                loading={loadingCV}
                                loadingPosition="start"
                                startIcon={<NoteAdd color={button.color} />}
                            />
                        </span>
                        </Tooltip>
                    ))}
            </Box>
        );
    };



    const getIframe = async (cv) => {
        const res = await getCvBlob(cv)
        const fileUrl = window.URL.createObjectURL(res.data);
        const filename = res.config.url.split('/').pop();
        return (
            <div>
                <PdfPreview fileUrl={fileUrl} fileName={filename}/>
            </div>
        );
    }

    const handleOpen = async () => {
        try {
            setLoading(true)
            const iframe = await getIframe(searchCv[0]?.image_name ?? searchCv[0]?.imageName)
            setIframe(iframe)
            setOpen(true);
        } catch (e) {
            snackbarShowMessage("Impossible de charger le cv, fichier physique introuvable", "error", 4000)
        }
        finally {
            setLoading(false)
        }
    };

    const handleClose = () => setOpen(false);


    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 className={classes.modalTitle}>
                CV CANDIDAT
            </h2>
            {iframe}
            <div className={classes.actionBar}>
                <Button className={classes.closeButton} onClick={handleClose}>
                    Close
                </Button>
            </div>
        </div>
    );
    return (
        <>
            {handleShowButton()}
            <Modal
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                open={open}
                onClose={handleClose}
            >
                <Fade in={open}>{body}</Fade>
            </Modal>
            <input
                type="file"
                onChange={(e) => {
                    const file = e.target.files[0];
                    if (file && file.type !== 'application/pdf') {
                        snackbarShowMessage('Veuillez télécharger uniquement des fichiers PDF.', 'error', 4000);
                        return;
                    }
                    setCvUpload(file);
                }}
                accept=".pdf"
                style={{ display: "none" }}
                ref={uploadRef}
                multiple
            />
        </>
    );
}
export default withSnackbar(ShowCvCandidat);
